import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import indexStyles from "./index.module.css"

import headerImage from "../images/stodgy-home-header.jpg"

const IndexPage = ({ data }) => (
    <Layout>
        <SEO title="Home" />
        <img src={headerImage} className={indexStyles.backgroundImage} alt="Wood fence with vine."/>
        <div className={indexStyles.backgroundColor}></div>
        <h1 className={indexStyles.title}>{data.markdownRemark.frontmatter.header1}<br />{data.markdownRemark.frontmatter.header2}</h1>
        <p className={indexStyles.subtitle}>{data.markdownRemark.frontmatter.header3}</p>
        {/* <a href="https://egiftcards.spoton.com/3283/601b02e424b4c40a03a15129"><div className={indexStyles.subscriptionButton}>BUY E-GIFT CARD</div></a> */}
        <div className={indexStyles.welcomeSection}>
            <h2>WELCOME TO STODGY!</h2>
            <p>{data.markdownRemark.frontmatter.description}</p>
            <p>{data.markdownRemark.frontmatter.descriptionTwo}</p>
        </div>
        <div className={indexStyles.addressSection}>
            <h2>LOCATION</h2>
            <address>
                1802 Laporte Ave.<br />
                Fort Collins, CO 80521
            </address>
        </div>
        <div className={indexStyles.hoursSection}>
            <h2>HOURS</h2>
            <ul>
                {data.markdownRemark.frontmatter.specialHours && <li className={indexStyles.yellowHours}>{data.markdownRemark.frontmatter.specialHours}</li>}
                <li>{data.markdownRemark.frontmatter.hours1}</li>
                <li>{data.markdownRemark.frontmatter.hours2}</li>
                <li>{data.markdownRemark.frontmatter.hours3}</li>
                {data.markdownRemark.frontmatter.hours4 && <li>{data.markdownRemark.frontmatter.hours4}</li>}
                {data.markdownRemark.frontmatter.hours5 && <li>{data.markdownRemark.frontmatter.hours5}</li>}
            </ul>
        </div>
        <div className={indexStyles.contactSection}>
            <h2>CONTACT</h2>
            <a href="mailto:info@stodgybrewing.com" className={indexStyles.email}>info@stodgybrewing.com</a>
        </div>
        <div className={indexStyles.hoursSection}>
            <h2>POLICIES</h2>
            <ul>
                <li><Link to="/policies/" className={indexStyles.yellowHours}>Children</Link></li>
                <li><Link to="/policies/" className={indexStyles.yellowHours}>Dogs</Link></li>
            </ul>
        </div>
        <a href="https://www.toasttab.com/stodgybrewing/giftcards"><div className={indexStyles.subscriptionButton}>BUY E-GIFT CARD</div></a>
        <Img fluid={data.beerGlassImage.childImageSharp.fluid} alt="" className={indexStyles.beerGlassImage} />
    </Layout>
)

export const query = graphql`
  query {
    beerGlassImage: file(relativePath: { eq: "beer_glass_foam_homepage.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(frontmatter: {templateKey: {eq: "homePage"}}) {
      frontmatter {
        header1
        header2
        header3
        description
        descriptionTwo
        specialHours
        hours1
        hours2
        hours3
        hours4
        hours5
      }
    }
  }
`

export default IndexPage
